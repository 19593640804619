import { useState } from 'react';
import { MEASUREMENT_DATA_INITIAL_STATE } from './initial-state';
import { MeasurementDataModel } from './measurement-state.model';

const useMeasurementData = () => {
  const [measurementData, setMeasurementData] = useState<MeasurementDataModel>(
    MEASUREMENT_DATA_INITIAL_STATE
  );

  const resetMeasurementData = () => {
    setMeasurementData(MEASUREMENT_DATA_INITIAL_STATE);
  };

  return {
    measurementState: measurementData,
    updateMeasurementData: setMeasurementData,
    resetMeasurementData,
  };
};

export default useMeasurementData;

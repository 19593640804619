import React from 'react';
import { LoaderContainer, LoaderOverlay } from './styles';

const Loader: React.FC = () => {
  return (
    <LoaderOverlay data-testid="page-loader">
      <LoaderContainer></LoaderContainer>
    </LoaderOverlay>
  );
};

export default Loader;

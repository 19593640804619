import AddToCartModal from '../../components/add-to-cart-modal';
import Button from '../../components/button';
import Loader from '../../components/loader';
import NextAndBackButtons from '../../components/next-and-back-buttons';
import {
  API_STATUS,
  BUTTON_POSITIONS,
  PRICE_INFO_CONTAINER_TITLE,
} from '../../utils/constants';
import { Page } from '../pages.model';
import { AddToCartPage } from './addtocart.model';
import {
  BrandAndCodeContainer,
  BrandName,
  ButtonContainer,
  CarouselAndDataContainer,
  CarouselImage,
  Divider,
  FinalProductData,
  ImageCarouselContainer,
  ImagesContainer,
  LeftControlContainer,
  PageContainer,
  PageTitle,
  PriceColumn,
  PriceInfo,
  PriceRow,
  PriceTitle,
  PriceValuesContainer,
  ProductCode,
  ProductInfo,
  ProductTitle,
  RightControlContainer,
  SpecificationColumn1,
  SpecificationColumn2,
  SpecificationRow,
  SpecificationsContainer,
  VolverButtonContainer,
} from './styles';
import useAddToCart from './useAddToCart';
import useImageCarousel from './useImageCarousel';
import { ReactComponent as ControlIcon } from '../../assets/icons/right-control.svg';

const AddToCart: React.FC<{ data: Page }> = ({ data }) => {
  const pageData = data as AddToCartPage;
  const {
    pageTitle,
    selectedSpecs,
    isMobile,
    cartUrl,
    isCartPageOpenInNewTab,
    isModalOpen,
    nextButtonText,
    backButtonText,
    brandName,
    productCode,
    productTitle,
    priceInfo,
    totalPrice,
    productDetailsApiStatus,
    addToCartApiStatus,
    images,
    handleAddToCart,
  } = useAddToCart(pageData);
  const {
    isLeftControlVisible,
    isRightControlVisible,
    currentImageIndex,
    carouselImages,
    handleLeftControlClick,
    handleRightControlClick,
    onKeyDownLeftControl,
    onKeyDownRightControl,
  } = useImageCarousel(images);

  if (productDetailsApiStatus === API_STATUS.LOADING) {
    return <Loader />;
  }

  if (productDetailsApiStatus === API_STATUS.ERROR) {
    return <div>Failed to load product details</div>;
  }

  return (
    <PageContainer>
      <ProductInfo>
        {!isMobile && (
          <VolverButtonContainer data-testid="desktop-buttons">
            <NextAndBackButtons
              backButtonText={backButtonText}
              hideNextButton={true}
              isIconVisible={true}
              position={BUTTON_POSITIONS.LEFT}
            />
          </VolverButtonContainer>
        )}
        <PageTitle>{pageTitle}</PageTitle>
        <CarouselAndDataContainer>
          <ImageCarouselContainer>
            <ImagesContainer>
              {carouselImages.map((image, index) => (
                <CarouselImage
                  key={image.url}
                  src={image.url}
                  alt={productTitle}
                  index={index}
                  currentimageindex={currentImageIndex}
                />
              ))}
            </ImagesContainer>
            {isLeftControlVisible && (
              <LeftControlContainer
                data-testid="left-control"
                onClick={handleLeftControlClick}
                tabIndex={0}
                onKeyDown={onKeyDownLeftControl}
              >
                <ControlIcon />
              </LeftControlContainer>
            )}
            {isRightControlVisible && (
              <RightControlContainer
                data-testid="right-control"
                onClick={handleRightControlClick}
                tabIndex={0}
                onKeyDown={onKeyDownRightControl}
              >
                <ControlIcon />
              </RightControlContainer>
            )}
          </ImageCarouselContainer>

          <FinalProductData>
            <BrandAndCodeContainer>
              <BrandName>{brandName}</BrandName>
              <ProductCode>Código {productCode}</ProductCode>
            </BrandAndCodeContainer>
            <ProductTitle>{productTitle}</ProductTitle>
            <SpecificationsContainer>
              {selectedSpecs &&
                selectedSpecs.length > 0 &&
                selectedSpecs.map((spec) => {
                  const { key, value } = spec;

                  return (
                    <SpecificationRow key={key}>
                      <SpecificationColumn1>{key}</SpecificationColumn1>
                      <SpecificationColumn2>{value}</SpecificationColumn2>
                    </SpecificationRow>
                  );
                })}
            </SpecificationsContainer>
          </FinalProductData>
        </CarouselAndDataContainer>
      </ProductInfo>
      <PriceInfo>
        <PriceTitle>{PRICE_INFO_CONTAINER_TITLE}</PriceTitle>
        <PriceValuesContainer>
          {priceInfo &&
            priceInfo.length > 0 &&
            priceInfo.map((price) => {
              const { key, value } = price;

              return (
                <PriceRow key={key}>
                  <PriceColumn>{key}</PriceColumn>
                  <PriceColumn>{value}</PriceColumn>
                </PriceRow>
              );
            })}
          <Divider />
          <PriceRow className="total">
            <PriceColumn>Total</PriceColumn>
            <PriceColumn>{totalPrice}</PriceColumn>
          </PriceRow>
          <ButtonContainer data-testid="addtocart-button">
            <Button
              buttonText={nextButtonText}
              onButtonClick={handleAddToCart}
            />
          </ButtonContainer>
        </PriceValuesContainer>
      </PriceInfo>
      {isMobile && (
        <VolverButtonContainer data-testid="mobile-buttons">
          <NextAndBackButtons
            backButtonText={backButtonText}
            hideNextButton={true}
          />
        </VolverButtonContainer>
      )}
      {isModalOpen && (
        <AddToCartModal
          cartUrl={cartUrl}
          isCartPageOpenInNewTab={isCartPageOpenInNewTab}
        />
      )}
      {addToCartApiStatus === API_STATUS.LOADING && <Loader />}
      {/* TODO: handle add to cart failed case */}
    </PageContainer>
  );
};

export default AddToCart;

// TODO: failed modal case

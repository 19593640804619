import styled from 'styled-components';
import { mediaQuery } from '../../../utils/media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.desktopToUp(`
      max-width: 1280px;
      margin: 0 auto;
  `)}
`;

import { useReducer } from 'react';
import { initialState } from './initial-state';
import { reducerFn } from './reducer';
import { ACTIONS } from './constants';
import { AppDataModel } from './app-data.model';

const useAppData = () => {
  const [state, dispatch] = useReducer(reducerFn, initialState);
  const updateAppData = (appData: Partial<AppDataModel>) => {
    dispatch({
      type: ACTIONS.UPDATE_APP_DATA,
      payload: appData,
    });
  };

  const updateStepperInfo = (stepperInfo: Partial<AppDataModel['stepper']>) => {
    dispatch({
      type: ACTIONS.UPDATE_STEPPER_INFO,
      payload: stepperInfo,
    });
  };

  return {
    appState: state,
    appActions: {
      updateAppData,
      updateStepperInfo,
    },
  };
};

export default useAppData;

import styled from 'styled-components';
import { COLORS, ZINDEX } from '../../../utils/constants/styles';
import { mediaQuery } from '../../../utils/media-query';

export const LoaderOverlay = styled.div`
  position: fixed;
  z-index: ${ZINDEX.LEVEL_10};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  border-radius: 50%;
  border: 5px solid ${COLORS.PRIMARY};
  border-top-color: ${COLORS.WHITE};
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${mediaQuery.desktopToUp(`
    width: 72px;
    height: 72px;
    margin-top: -36px;
    margin-left: -36px;
  `)}
`;

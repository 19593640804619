import { useEffect, useRef } from 'react';
import { resizeObserverHandler } from '../utils';
import { APP_URL_QUERY_PARAMS } from '../utils/constants';

const useResizeObserver = (currentPosition: number) => {
  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isMobileApp =
      queryParams.get(APP_URL_QUERY_PARAMS.IS_MOBILE_APP) === 'true';
    if (isMobileApp) return;

    const resizeObserver = new ResizeObserver((entries) =>
      resizeObserverHandler(entries)
    );

    if (appRef.current) {
      resizeObserver.observe(appRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentPosition]);

  return { appRef };
};

export default useResizeObserver;

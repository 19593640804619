import styled from 'styled-components';
import { mediaQuery } from '../../../utils/media-query';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > .ghost {
    order: 1;
  }
  ${mediaQuery.desktopToUp(`
    flex-direction: row;
    & > .ghost {
      order: 0;
    }
  `)}
`;

/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './providers';
import { MeasurementDataProvider } from './providers/measurement';
import { TimeLineProvider } from './providers/timeline';
import { AddToCartProvider } from './providers/addtocart';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <MeasurementDataProvider>
        <TimeLineProvider>
          <AddToCartProvider>
            <App />
          </AddToCartProvider>
        </TimeLineProvider>
      </MeasurementDataProvider>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

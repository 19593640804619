import AddToCart from '../addtocart';
import Introduction from '../introduction';
import Measurement from '../measurement';
import ProductDetails from '../product-details';
import Timeline from '../timeline';

export const PAGE_NAMES = {
  JOURNEYS_PAGE_INTRODUCTION: 'journeys_page_introduction',
  JOURNEYS_PAGE_TIMELINE: 'journeys_page_timeline',
  JOURNEYS_PAGE_MEASUREMENT: 'journeys_page_measurement',
  JOURNEYS_PAGE_PRODUCT_DETAILS: 'journeys_page_product_details',
  JOURNEYS_PAGE_ADDTOCART: 'journeys_page_addtocart',
};

export const PAGES = {
  [PAGE_NAMES.JOURNEYS_PAGE_INTRODUCTION]: Introduction,
  [PAGE_NAMES.JOURNEYS_PAGE_TIMELINE]: Timeline,
  [PAGE_NAMES.JOURNEYS_PAGE_MEASUREMENT]: Measurement,
  [PAGE_NAMES.JOURNEYS_PAGE_PRODUCT_DETAILS]: ProductDetails,
  [PAGE_NAMES.JOURNEYS_PAGE_ADDTOCART]: AddToCart,
};

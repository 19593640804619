import styled, { css } from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';
import { mediaQuery } from '../../../utils/media-query';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.desktopToUp(`
    flex-direction: row;
    gap: 12px;
  `)}
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  background-color: ${COLORS.WHITE};
  padding: 16px 16px 24px;

  ${mediaQuery.desktopToUp(`
    padding: 0 24px 24px 40px;
    flex-grow: 1;
    max-width: 938px;
  `)}
`;

export const PageTitle = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  line-height: 20px;
  margin-bottom: 8px;
  font-weight: 700;

  ${mediaQuery.desktopToUp(`
      font-size: ${FONT_SIZES.DESKTOP_HEADING};
      line-height: 31px;
      margin-bottom: 16px;
      margin-top: 16px;
  `)}
`;

export const CarouselAndDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery.desktopToUp(`
     flex-direction: row;
     gap: 16px;
     align-items: flex-start;
  `)}
`;

export const ImageCarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 500px;
  // TODO: Dynamic height
  ${mediaQuery.desktopToUp(`
     width: 192px;
     height: 192px;
 `)}
`;

export const ImagesContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const CarouselImage = styled.img<{
  index: number;
  currentimageindex: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: ${({ index, currentimageindex }) =>
    index === currentimageindex
      ? 'translateX(0)'
      : index < currentimageindex
        ? 'translateX(-100%)'
        : 'translateX(100%)'};

  transition: transform 0.5s ease-in-out;
`;

const ControlsCss = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const RightControlContainer = styled.div`
  ${ControlsCss}
  right: -10px;
  svg {
    transform: scale(0.6);
  }

  ${mediaQuery.desktopToUp(`
      right: -20px;
  `)}
`;

export const LeftControlContainer = styled.div`
  ${ControlsCss}
  left: -10px;
  svg {
    transform: scale(0.6) rotate(180deg);
  }

  ${mediaQuery.desktopToUp(`
      left: -20px;
  `)}
`;

export const FinalProductData = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.desktopToUp(`
      flex-grow: 1;
  `)}
`;

export const BrandAndCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${COLORS.NEUTRAL_GRAY_COLD};
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 16px;
  margin-bottom: 2px;
  margin-top: 16px;

  ${mediaQuery.desktopToUp(`
      margin-top: 0;
  `)}
`;

export const BrandName = styled.div``;

export const ProductCode = styled.div``;

export const ProductTitle = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.DESKTOP_HEADING};
  line-height: 24px;
  margin-bottom: 4px;

  ${mediaQuery.desktopToUp(`
      margin-bottom: 16px;
  `)}
`;

export const SpecificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;

  ${mediaQuery.desktopToUp(`
      width: 356px;
  `)}
`;

export const SpecificationRow = styled.div`
  color: ${COLORS.BLACK};
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 16px;
  display: flex;
  border-top: 1px solid ${COLORS.MEDIUM_GRAY};
`;

const SpecificationColumn = css`
  padding: 12px;
  flex-basis: 50%;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const SpecificationColumn1 = styled.div`
  ${SpecificationColumn}
  background-color: ${COLORS.NEUTRAL_GRAY_LIGHT};
`;

export const SpecificationColumn2 = styled.div`
  ${SpecificationColumn}
  font-weight: 700;
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 0;

  ${mediaQuery.desktopToUp(`
      width: 293px;
      min-width: 293px;
      padding: 0;
  `)}
`;

export const PriceTitle = styled.div`
  font-size: ${FONT_SIZES.EXTRA_LARGE};
  line-height: 20px;
  font-weight: 700;
  color: ${COLORS.CHARCOAL_GRAY};
  margin-bottom: 8px;

  ${mediaQuery.desktopToUp(`
     margin-bottom: 16px;
  `)}
`;

export const PriceValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  border-radius: 10px;
  background-color: ${COLORS.WHITE};
  gap: 8px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 14px;
  color: ${COLORS.CHARCOAL_GRAY};

  &.total {
    font-weight: 700;
    font-size: ${FONT_SIZES.STANDARD};
    line-height: 17px;
    color: ${COLORS.DARK_GRAY};
  }
`;

export const PriceColumn = styled.div``;

export const Divider = styled.div`
  border-top: 1px solid ${COLORS.SILVER_GRAY};
`;

export const ButtonContainer = styled.div`
  margin-top: 8px;

  ${mediaQuery.desktopToUp(`
      margin-top: 16px;
  `)}
`;

export const VolverButtonContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;

  ${mediaQuery.desktopToUp(`
      margin-top: 0;
      margin-bottom: 0;
  `)}
`;

import { Stepper as StepperV2 } from '@digital-retail/journey-ui-kit';
import { StepperContainer } from './styles';
import useStepper from './useStepper';

const Stepper: React.FC = () => {
  const { stepperTitles, currentStep, onClick } = useStepper();

  return (
    <StepperContainer data-testid="stepper">
      <StepperV2
        titles={stepperTitles}
        onClick={onClick}
        currentStep={currentStep}
      ></StepperV2>
    </StepperContainer>
  );
};

export default Stepper;

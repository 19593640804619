import { useEffect, useState } from 'react';
import { BREAKPOINTS, DEVICE_TYPES } from '../utils/constants/breakpoints';

const useDevice = (deviceType = DEVICE_TYPES.mobile) => {
  const getWindowWidth = () => {
    const { innerWidth: width } = window;
    return width;
  };

  const getTypes = (width: number) => {
    return {
      isMobileOnly: width < BREAKPOINTS.tablet,
      isMobile: width < BREAKPOINTS.desktop,
      isTablet: width < BREAKPOINTS.desktop && width >= BREAKPOINTS.tablet,
      isDesktop: width >= BREAKPOINTS.desktop,
      isSmallDesktop:
        width < BREAKPOINTS.superDesktop && width >= BREAKPOINTS.desktop,
      isLargeDesktop: width >= BREAKPOINTS.superDesktop,
    };
  };

  const initialWidth = deviceType === DEVICE_TYPES.mobile ? 350 : 1200;
  const [windowWidth, updateWindowWidth] = useState(initialWidth);
  const [types, setTypes] = useState(getTypes(initialWidth));

  useEffect(() => {
    function handleResize() {
      const width = getWindowWidth();
      updateWindowWidth(width);
      setTypes(getTypes(width));
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...types,
    windowWidth,
  };
};

export default useDevice;

import { createContext } from 'react';
import { MeasurementDataContextType } from './measurement-state.model';
import { initialMeasurementContext } from './initial-state';
import useMeasurementData from './useMeasurementData';

const MeasurementDataContext = createContext<MeasurementDataContextType>(
  initialMeasurementContext
);

const MeasurementDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useMeasurementData();

  return (
    <MeasurementDataContext.Provider value={value}>
      {children}
    </MeasurementDataContext.Provider>
  );
};

export { MeasurementDataContext, MeasurementDataProvider };

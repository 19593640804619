import React from 'react';
import { createContext } from 'react';
import { AddToCartContextModel } from './addtocart-data.model';
import { initialCartData } from './initial-data';
import useAddToCartState from './useAddToCartState';

const AddToCartContext = createContext<AddToCartContextModel>(initialCartData);

const AddToCartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const values = useAddToCartState();
  return (
    <AddToCartContext.Provider value={values}>
      {children}
    </AddToCartContext.Provider>
  );
};

export { AddToCartContext, AddToCartProvider };

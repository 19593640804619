import styled from 'styled-components';
import { COLORS, FONT_SIZES, ZINDEX } from '../../../utils/constants/styles';
import { mediaQuery } from '../../../utils/media-query';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: ${ZINDEX.LEVEL_9};
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  background-color: ${COLORS.WHITE};
  z-index: ${ZINDEX.LEVEL_10};
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 500px;

  ${mediaQuery.desktopToUp(`
      width: 442px;
      bottom: auto;
  `)}
`;

export const ModalHeader = styled.div`
  display: flex;
  background-color: ${COLORS.LIGHT_GREEN};
  padding: 12px;

  ${mediaQuery.desktopToUp(`
      padding: 24px;
      align-items: center;
  `)}
`;

export const ModalTitleIcon = styled.img`
  margin-right: 4px;
  width: 24px;
  height: 24px;

  ${mediaQuery.desktopToUp(`
      margin-right: 16px;
      width: 32px;
      height: 32px;
  `)}
`;

export const ModalTitle = styled.div`
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
  color: ${COLORS.DARK_GREEN};

  ${mediaQuery.mobileToDesktop(`
      font-size: ${FONT_SIZES.MOBILE_HEADING};
      line-height: 22.1px;
      
  `)}
`;

export const ModelBody = styled.div``;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 28px 15px 8px;
  gap: 10px;

  ${mediaQuery.desktopToUp(`
      padding: 24px;
      justify-content: space-around;
      flex-direction: row;
      gap: 0;
  `)}
`;

export const KeepBuyingButton = styled.a`
  text-decoration: none;
  color: ${COLORS.BLUE_GRAY}
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
  padding: 12px 16px;
  cursor: pointer;
  align-self: center;

  ${mediaQuery.desktopToUp(`
      align-self: auto;
  `)}
`;

export const GoToCartButton = styled.a`
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
  padding: 12px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  order: -1;

  ${mediaQuery.desktopToUp(`
      width: 166px;
      order: 0;
      line-height: 18.2px;
  `)}

  &:focus {
    outline: 2px solid ${COLORS.PRIMARY};
  }
`;

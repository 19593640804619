import { AddToCartInfo, CartLine } from '../models/addtocart.service.model';
import {
  getAuthSid,
  getCartId,
  getCurrentAppEnvironment,
  getCurrentTenant,
  getPoliticalId,
  getPriceGroupId,
} from '../../utils';
import { CATALYST_CART_URL, TENANTS } from '../../utils/constants/urls';

export const getCustomInfo = (
  rawCustomInfo: AddToCartInfo['customInformation'],
  productId: string,
  sellerSkuId: string
) => {
  const { journeysType, attributeMapping, selectedSpecs } = rawCustomInfo;
  const group = `journey-${journeysType.toLowerCase()}`;
  const selectedOptions = attributeMapping.map((item) => {
    const { customInfoName, productAttributeName } = item;
    const value = selectedSpecs[productAttributeName];
    return {
      name: customInfoName,
      values: [value],
      group,
    };
  });

  const customInfo = [
    {
      name: 'journeyType',
      values: [journeysType],
      group: 'journey',
    },
    {
      name: 'sku_base',
      values: [productId],
      group,
    },
    {
      name: 'seller-sku',
      values: [sellerSkuId],
      group,
    },
    ...selectedOptions,
  ];

  return customInfo;
};

export const getFACatalystCartLines = (data: AddToCartInfo): CartLine[] => {
  const { skuId, productId, quantity, customInformation, sellerSkuId } = data;
  const cartLines = {
    cartLineNumber: skuId,
    // cartline number will be variantid
    item: {
      variantId: skuId,
      offeringId: productId,
    },
    quantity: {
      quantityNumber: quantity,
      quantityUnit: 'C/U',
    },
    customInfo: getCustomInfo(customInformation, productId, sellerSkuId),
  };

  return [cartLines];
};

export const getAddToCartFAUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const cartId = getCartId();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const url = new URL(`${baseUrl}/${CATALYST_CART_URL}`);
  if (cartId) {
    url.searchParams.append('cartId', cartId);
  }

  return url.toString();
};

export const getRequestBodyFA = (data: AddToCartInfo) => {
  const cartLines = getFACatalystCartLines(data);
  const politicalAreaId = getPoliticalId();
  const priceGroup = getPriceGroupId();
  const requestBody = {
    data: {
      cart: {
        cartLines,
      },
    },
    metadata: {
      politicalAreaId,
      priceGroup,
    },
  };

  return requestBody;
};

export const addToCartFA = async (productData: AddToCartInfo) => {
  try {
    const url = getAddToCartFAUrl();
    const headers = {
      'content-type': 'application/json',
      'x-channel-id': 'WEB',
      'x-ecomm-name': 'marketplace-cl',
      sid: '',
    };
    const authSid = getAuthSid();
    if (authSid) {
      headers.sid = authSid;
    }
    const body = getRequestBodyFA(productData);

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    if (response.status === 200) {
      const jsonRes = await response.json();
      const cartAlerts = jsonRes?.data?.cart?.cartAlerts || [];
      if (cartAlerts.length === 0) {
        return { success: true };
      }
    }

    return { success: false };
  } catch (error) {
    return { success: false };
  }
};

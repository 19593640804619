import { useContext } from 'react';
import { PAGE_NAMES } from '../pages/page-wrapper/constants';
import { MeasurementDataContext } from '../providers/measurement';
import { noop } from '../utils';
import { AddToCartContext } from '../providers/addtocart';
import { TimeLineContext } from '../providers/timeline';
import { Page } from '../pages/pages.model';

const useResetState = () => {
  const { resetMeasurementData } = useContext(MeasurementDataContext);
  const { resetAddToCartState } = useContext(AddToCartContext);
  const { resetTimelineState } = useContext(TimeLineContext);

  const resetHandlers = {
    [PAGE_NAMES.JOURNEYS_PAGE_INTRODUCTION]: noop,
    [PAGE_NAMES.JOURNEYS_PAGE_TIMELINE]: resetTimelineState,
    [PAGE_NAMES.JOURNEYS_PAGE_MEASUREMENT]: resetMeasurementData,
    [PAGE_NAMES.JOURNEYS_PAGE_PRODUCT_DETAILS]: noop,
    //TODO: update it with product details reset state handler
    [PAGE_NAMES.JOURNEYS_PAGE_ADDTOCART]: resetAddToCartState,
  };

  const pageNames = Object.values(PAGE_NAMES);

  const resetCurrentStepState = (currentPageName: string) => {
    if (!pageNames.includes(currentPageName)) {
      return;
    }
    const currentpageResetHandler = resetHandlers[currentPageName];
    currentpageResetHandler();
  };

  const resetNextStepsToCurrentStep = (currentStep: number, pages: Page[]) => {
    const nextPages = pages.slice(currentStep + 1);
    const nextPagesNames = nextPages
      .map((page) => page?.contentTypeUid)
      .filter((pageName) => pageNames.includes(pageName));
    nextPagesNames.forEach((pageName) => resetHandlers[pageName]());
  };

  return {
    resetCurrentStepState,
    resetNextStepsToCurrentStep,
  };
};

export default useResetState;

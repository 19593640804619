import { useEffect, useState } from 'react';
import { handleButtonsClick } from '../../utils';

const useImageCarousel = (images: { url: string }[] = []) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLeftControlVisible, setIsLeftControlVisible] = useState(false);
  const [isRightControlVisible, setIsRightControlVisible] = useState(false);
  useEffect(() => {
    if (images.length > 1) {
      setIsRightControlVisible(true);
    }
  }, [images]);

  const updateControlsVisibility = (index: number) => {
    let isLeftVisible = true;
    let isRightVisible = true;
    // Hide left control at the start (index 0)
    if (index === 0) {
      isLeftVisible = false;
    }
    // Hide right control at the end (index equals the last index)
    if (index === images.length - 1) {
      isRightVisible = false;
    }
    setIsLeftControlVisible(isLeftVisible);
    setIsRightControlVisible(isRightVisible);
  };

  const handleLeftControlClick = () => {
    const updatedIndex = currentImageIndex - 1;
    updateControlsVisibility(updatedIndex);
    setCurrentImageIndex(updatedIndex);
  };

  const handleRightControlClick = () => {
    const updatedIndex = currentImageIndex + 1;
    updateControlsVisibility(updatedIndex);
    setCurrentImageIndex(updatedIndex);
  };

  const onKeyDownLeftControl = (event: React.KeyboardEvent<HTMLDivElement>) => {
    handleButtonsClick(event, handleLeftControlClick);
  };

  const onKeyDownRightControl = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    handleButtonsClick(event, handleRightControlClick);
  };

  return {
    isLeftControlVisible,
    isRightControlVisible,
    currentImageIndex,
    carouselImages: images,
    handleLeftControlClick,
    handleRightControlClick,
    onKeyDownLeftControl,
    onKeyDownRightControl,
  };
};

export default useImageCarousel;

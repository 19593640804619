import styled from 'styled-components';
import { mediaQuery } from '../../../utils/media-query';

export const StepperContainer = styled.div`
  padding: 17px 0;
  width: 100%;

  ${mediaQuery.desktopToUp(`
      padding: 24px 0px;
  `)}
`;

import React from 'react';
import { Page } from '../pages.model';
import { CardModel, IntroductionPage } from './introduction.model';
import {
  PageButton,
  PageCards,
  PageContainer,
  PageContent,
  PageImage,
  PageTitle,
} from './styles';
import useDevice from '../../hooks/useDevice';
import { Card } from '../../components';
import usePageWrapper from '../page-wrapper/usePageWrapper';
import DOMPurify from 'dompurify';
import { defaultNextButtonText } from './constants';
import { updateImageUrlHost } from '../../utils';

const Introduction: React.FC<{ data: Page }> = ({ data }) => {
  const pageData = data as IntroductionPage;
  const { isDesktop } = useDevice();
  const { goToNextPage, onKeyDownNextPage } = usePageWrapper();
  const sanitizedPageTitle = DOMPurify.sanitize(pageData.pageTitle || '');
  const cardsData = pageData.cards || [];
  const imageUrl = pageData?.showcaseImageDesktop?.url || '';
  const updatedImageUrl = updateImageUrlHost(imageUrl);
  const imageAlt = pageData?.showcaseImageDesktop?.title || '';
  const nextButtonText = pageData.nextButtonText || defaultNextButtonText;

  return (
    <PageContainer>
      <PageContent>
        <PageTitle
          dangerouslySetInnerHTML={{ __html: sanitizedPageTitle }}
        ></PageTitle>
        <PageCards>
          {cardsData.map((cardData: CardModel) => {
            return <Card data={cardData} key={cardData.title || ''} />;
          })}
        </PageCards>
        <PageButton
          onClick={goToNextPage}
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownNextPage}
        >
          {nextButtonText}
        </PageButton>
      </PageContent>
      {isDesktop && (
        <PageImage src={updatedImageUrl} alt={imageAlt}></PageImage>
      )}
    </PageContainer>
  );
};

export default Introduction;

import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';
import { mediaQuery } from '../../../utils/media-query';
import { PageContainerStyles } from '../../../utils/styles';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${PageContainerStyles}
`;

export const PageTitle = styled.div`
  font-weight: 700;
  color: ${COLORS.DARK_GRAY};
  line-height: 20px;
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  margin-bottom: 16px;

  ${mediaQuery.desktopToUp(`
    line-height: 31px;
    font-size: ${FONT_SIZES.DESKTOP_HEADING};
  `)}
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.desktopToUp(`
    flex-direction: row;
  `)}
`;

export const PageColumn1 = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.desktopToUp(`
    margin-top: 24px;
    margin-right: 80px;
  `)}
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content: center;

  ${mediaQuery.desktopToUp(`
    margin-bottom: 32px;
    gap: 24px;
  `)}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div:nth-child(2) {
    width: 100%;
    border-color: ${COLORS.NEUTRAL_GRAY_60};
    border-bottom-width: ${({ theme }) =>
      theme.inputThemeUpdated.border.bottom};

    &.error {
      border-color: ${COLORS.ERROR};
    }

    input::placeholder {
      color: ${COLORS.NEUTRAL_GRAY_60};
    }

    input {
      font-size: ${FONT_SIZES.REGULAR};
      line-height: 24px;
      color: ${COLORS.DARK_GRAY_VARIANT_1};
    }
  }

  div:nth-child(3) {
    &.error {
      color: ${COLORS.ERROR};
      margin-top: 2px;
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 8px;
`;

export const LabelIcon = styled.img``;

export const LabelText = styled.div`
  font-size: ${FONT_SIZES.REGULAR};
  font-weight: 500;
  color: ${COLORS.NEUTRAL_GRAY_100};
  line-height: 24px;
`;

export const PageImage = styled.img`
  width: 269px;
  height: 203px;
  margin: 0 auto;
  margin-bottom: 16px;
  order: -1;

  ${mediaQuery.desktopToUp(`
    width: 341px;
    height: 256px;
    margin-right: 22px;
    order: 0;
  `)}
`;

export const InfoContainer = styled.div`
  padding: 16px;
  line-height: 20px;
  background-color: ${COLORS.NEUTRAL_GRAY_LIGHT};
  border-radius: 8px;
  color: ${COLORS.DARK_GRAY};
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 24px;

  ${mediaQuery.desktopToUp(`
    max-width: 284px;
    height: 179px;
  `)}
`;

export const InfoTitle = styled.div`
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const InfoText = styled.div`
  font-size: ${FONT_SIZES.REGULAR};
`;

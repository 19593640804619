import { handleButtonsClick, updateImageUrlHost } from '../../utils';
import { BUTTON_POSITIONS } from '../../utils/constants';
import { IMAGE_URLS } from '../../utils/constants/urls';
import { ButtonIcon, PageButton } from './styles';

const Button: React.FC<{
  buttonText: string;
  isDisabled?: boolean;
  buttonType?: 'filled' | 'ghost';
  isIconVisible?: boolean;
  position?: 'left' | 'right';
  onButtonClick: () => void;
}> = ({
  buttonText,
  isDisabled = false,
  buttonType = 'filled',
  isIconVisible = false,
  position = BUTTON_POSITIONS.RIGHT,
  onButtonClick,
}) => {
  const disabledClass = isDisabled ? 'disabled' : '';
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    handleButtonsClick(event, onButtonClick);
  };

  return (
    <PageButton
      className={`${buttonType} ${disabledClass} ${position}`}
      onClick={onButtonClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleOnKeyDown}
    >
      {isIconVisible && position === BUTTON_POSITIONS.LEFT && (
        <ButtonIcon
          className="left"
          src={updateImageUrlHost(IMAGE_URLS.CHEVRON_UP.URL)}
          alt={IMAGE_URLS.CHEVRON_UP.ALT_TEXT}
        />
      )}
      <span>{buttonText}</span>
      {isIconVisible && position === BUTTON_POSITIONS.RIGHT && (
        <ButtonIcon
          src={updateImageUrlHost(IMAGE_URLS.CHEVRON_UP.URL)}
          alt={IMAGE_URLS.CHEVRON_UP.ALT_TEXT}
        />
      )}
    </PageButton>
  );
};

export default Button;

import { noop } from '../../utils';
import { AddToCartContextModel } from './addtocart-data.model';

export const initialCartData: AddToCartContextModel = {
  priceInfo: {
    Ventana: '',
    Instalacion: '',
  },
  totalPrice: '',
  productCode: '',
  productTitle: '',
  brandName: '',
  images: [],
  serviceSkuId: '',
  sellerSkuId: '',
  setAddToCartData: noop,
  resetAddToCartState: noop,
};

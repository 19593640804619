import { createContext } from 'react';
import { AppDataContextType } from './app-data.model';
import { initialAppContext } from './initial-state';
import useAppData from './useAppData';

const AppContext = createContext<AppDataContextType>(initialAppContext);

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = useAppData();

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };

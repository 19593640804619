import './App.css';
import useApp from './hooks/useApp';
import { ThemeProvider } from 'styled-components';
import { PageWrapper } from './pages';

function App() {
  const { themeData } = useApp();

  return (
    <ThemeProvider theme={themeData}>
      <PageWrapper />
    </ThemeProvider>
  );
}

export default App;

import { useState } from 'react';
import { AddToCartPageState } from './addtocart-data.model';
import { initialCartData } from './initial-data';

const useAddToCartState = () => {
  const {
    brandName,
    productCode,
    productTitle,
    images,
    priceInfo,
    totalPrice,
    serviceSkuId,
    sellerSkuId,
  } = initialCartData;
  const initialCartState = {
    brandName,
    productCode,
    productTitle,
    images,
    priceInfo,
    totalPrice,
    serviceSkuId,
    sellerSkuId,
  };
  const [addToCartData, setAddToCartData] =
    useState<AddToCartPageState>(initialCartState);

  const resetAddToCartState = () => {
    setAddToCartData(initialCartState);
  };

  return {
    ...addToCartData,
    setAddToCartData,
    resetAddToCartState,
  };
};

export default useAddToCartState;

import styled from 'styled-components';
import { mediaQuery } from '../../../utils/media-query';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';
import { PageContainerStyles } from '../../../utils/styles';

export const PageContainer = styled.div`
  display: flex;
  gap: 24px;
  ${PageContainerStyles}
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.div`
  p:first-child {
    font-size: ${FONT_SIZES.EXTRA_LARGE};
    line-height: 26px;
    margin-bottom: 4px;
  }
  p:nth-child(2) {
    font-size: ${FONT_SIZES.REGULAR};
    line-height: 16px;
  }
  color: ${COLORS.DARK_GRAY};
  margin-bottom: 16px;
`;

export const PageCards = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 32px;

  ${mediaQuery.desktopToUp(`
    margin-bottom: 24px;
  `)}
`;

export const PageButton = styled.div`
  display: flex;
  height: 42px;
  padding: 0 32px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  cursor: pointer;
  font-weight: 700;
  font-size: ${FONT_SIZES.LARGE};
  line-height: 22px;
  letter-spacing: 0.16px;

  ${mediaQuery.tabletToUp(`
    width: 120px;
  `)}
`;

export const PageImage = styled.img`
  width: 495px;
  object-fit: contain;
`;

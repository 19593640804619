import { AddToCartPageState } from '../providers/addtocart/addtocart-data.model';
import {
  getCurrentAppEnvironment,
  getCurrentTenant,
  getProductDetailsApiQueryParams,
  isNormalPrice,
  isSodimacBusinessUnit,
} from '../utils';
import {
  HOME_IMPROVEMENT_SERVICE_BASE_URL,
  PRODUCT_DETAILS_API_QUERY_PARAMS,
  PRODUCT_DETAILS_URL,
} from '../utils/constants/urls';
import { ProductDetailsResponse } from './models/product-details.model';

export const getProductDetailsUrl = (productId: string, variantId: string) => {
  const tenant = getCurrentTenant();
  const businessUnit = tenant.substring(0, 2);
  const env = getCurrentAppEnvironment();
  const baseUrl = HOME_IMPROVEMENT_SERVICE_BASE_URL[businessUnit][env];
  const lowercaseTenant = tenant.toLowerCase();
  const url = new URL(`${baseUrl}/${PRODUCT_DETAILS_URL}/${lowercaseTenant}`);
  const { PRODUCT_ID, VARIANT_ID, ZONES, PGID, PID } =
    PRODUCT_DETAILS_API_QUERY_PARAMS;
  const { zones, pgId, pId } = getProductDetailsApiQueryParams();
  url.searchParams.append(PRODUCT_ID, productId);
  url.searchParams.append(VARIANT_ID, variantId);
  if (zones) {
    url.searchParams.append(ZONES, zones);
  }
  if (pgId) {
    url.searchParams.append(PGID, pgId);
  }
  if (pId) {
    url.searchParams.append(PID, pId);
  }

  return url.toString();
};

export const getPriceInfo = (
  prices: ProductDetailsResponse['variant']['prices'],
  serviceOptions: ProductDetailsResponse['serviceOptions'],
  productType: string
) => {
  const normalPriceData = prices.find((price) => {
    const { type = '' } = price;
    const upperCaseType = type.toUpperCase();

    return isNormalPrice(upperCaseType);
  });
  const { price: normalPriceValue = '', symbol = '$' } = normalPriceData || {};
  const trimmedSymbol = symbol.trim();
  const finalNormalPriceValue =
    typeof normalPriceValue === 'string'
      ? normalPriceValue
      : normalPriceValue[0];
  const normalPrice = `${trimmedSymbol}${finalNormalPriceValue}`;
  let priceInfo = {
    [productType]: normalPrice,
  };

  const installationPriceValue = serviceOptions?.price?.price || '';
  if (installationPriceValue) {
    // For Falabella is the logic to get the installation price correct?
    const installationPrice = `${trimmedSymbol}${installationPriceValue}`;
    priceInfo = {
      ...priceInfo,
      Instalación: installationPrice,
    };
  }

  return priceInfo;
};

export const getTotalPrice = (
  priceInfo: AddToCartPageState['priceInfo'],
  priceConfig: ProductDetailsResponse['priceConfig']
) => {
  const { decimalSeparator, thousandSeparator, precision, currencySymbol } =
    priceConfig;
  const allPrices = Object.values(priceInfo);
  const allUnFormattedPrices = allPrices.map((price) => {
    const unFormattedPriceArray = [];
    for (let i = 0; i < price.length; i++) {
      const char = price[i];
      if (char === currencySymbol) {
        unFormattedPriceArray.push('');
      } else if (char === thousandSeparator) {
        unFormattedPriceArray.push('');
      } else if (char === decimalSeparator) {
        unFormattedPriceArray.push('.');
      } else {
        unFormattedPriceArray.push(char);
      }
    }
    const unFormattedPrice = unFormattedPriceArray.join('');

    return Number(unFormattedPrice);
  });

  const totalPrice = allUnFormattedPrices.reduce((a, b) => a + b, 0);
  const finalTotalPrice = totalPrice.toFixed(precision);
  const [integerValue, decimalValue = ''] = finalTotalPrice.split('.');
  const formattedIntegerValue = integerValue
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
    .trim();
  const formattedTotalPrice = `${currencySymbol}${formattedIntegerValue}${
    decimalValue && decimalSeparator
  }${decimalValue}`;

  return formattedTotalPrice;
};

export const parseProductDetailsData = (
  data: ProductDetailsResponse,
  productType: string
): AddToCartPageState => {
  const {
    name,
    brandName,
    variant: { images = [], prices = [], sellerSkuId = '' },
    priceConfig,
    serviceOptions,
    serviceOption,
  } = data;

  const isSodimacBU = isSodimacBusinessUnit();
  const finalServiceOptions = isSodimacBU ? serviceOptions : serviceOption;
  const priceInfo = getPriceInfo(prices, finalServiceOptions, productType);
  const totalPrice = getTotalPrice(priceInfo, priceConfig);
  const serviceSkuId = finalServiceOptions?.id || '';
  const pCode = isSodimacBU ? '' : sellerSkuId;
  const parsedData = {
    brandName,
    productCode: pCode,
    productTitle: name,
    priceInfo,
    totalPrice,
    images: images,
    serviceSkuId,
    sellerSkuId,
  };

  return parsedData;
};

export const getProductDetails = async (
  productId: string,
  variantId: string,
  productType: string
) => {
  let finalResponse = {
    success: false,
    productDetails: {} as AddToCartPageState,
  };
  try {
    const url = getProductDetailsUrl(productId, variantId);
    const productDetails = await fetch(url);
    const productDetailsJson =
      (await productDetails.json()) as ProductDetailsResponse;
    const parsedData = parseProductDetailsData(productDetailsJson, productType);

    finalResponse = { success: true, productDetails: parsedData };
  } catch (error) {
    // TODO: handle error case
    return finalResponse;
  }

  return finalResponse;
};

import styled, { css } from 'styled-components';
import { COLORS, ZINDEX } from './constants/styles';
import { mediaQuery } from './media-query';

export const BlurLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  z-index: ${ZINDEX.LEVEL_1};
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 106.25%);
`;

export const BlurRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  z-index: ${ZINDEX.LEVEL_1};
  background: linear-gradient(
    270deg,
    #fff 0.52%,
    rgba(255, 255, 255, 0.7) 47.49%,
    rgba(255, 255, 255, 0) 99.72%
  );
`;

export const HideScrollBar = css`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ResetButtonStyles = css`
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: auto;
  }
`;

export const PageContainerStyles = css`
  padding: 16px 16px 32px 16px;
  background-color: ${COLORS.WHITE};

  ${mediaQuery.desktopToUp(`
      padding: 24px 40px 40px 40px;
  `)}
`;

import {
  getMobileAppStatus,
  isAddToCartAtgEnabled,
  isSodimacBusinessUnit,
} from '../../utils';
import { AddToCartInfo } from '../models/addtocart.service.model';
import { MOBILE_APP_EVENTS } from '../../utils/constants';
import {
  addToCartFA,
  getFACatalystCartLines,
} from './catalyst-addtocart.service';
import { atgAddToCart } from './atg-addtocart.service';

export const handleMobileAppAddToCart = (productsData: AddToCartInfo) => {
  const isSodimacBU = isSodimacBusinessUnit();
  const isAtgEnabled = isAddToCartAtgEnabled();
  let cartData = {};
  if (isSodimacBU && isAtgEnabled) {
    cartData = productsData;
  } else {
    cartData = getFACatalystCartLines(productsData);
  }

  window.parent.dispatchEvent(
    new CustomEvent(MOBILE_APP_EVENTS.ADD_TO_CART, {
      detail: {
        cartData,
      },
    })
  );
};

export const addToCart = async (productsData: AddToCartInfo) => {
  let res = { success: false };
  try {
    const isMobileApp = getMobileAppStatus();
    const isAtgEnabled = isAddToCartAtgEnabled();
    const isSodimacBU = isSodimacBusinessUnit();

    if (isMobileApp) {
      handleMobileAppAddToCart(productsData);
      return;
    } else if (isSodimacBU && isAtgEnabled) {
      res = await atgAddToCart(productsData);
    } else {
      res = await addToCartFA(productsData);
    }
  } catch (error) {
    // TODO: handle error case
    res = { success: false };
  }

  return res || { success: false };
};

/* istanbul ignore file */

import { Page } from '../pages.model';
import { TimelinePage } from './timeline.model';

const Timeline: React.FC<{ data: Page }> = ({ data }) => {
  const pageData = data as TimelinePage;

  return (
    <>
      <div>
        <b
          style={{
            marginBottom: '20px',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Timeline Page
        </b>
      </div>
      <div
        style={{
          wordBreak: 'break-all',
        }}
      >
        {JSON.stringify(pageData)}
      </div>
    </>
  );
};

export default Timeline;

/* istanbul ignore file */
import { useState } from 'react';
import { Timeline } from '../../pages/timeline/timeline.model';

const useTimelineState = () => {
  const [subSteps, updateSubSteps] = useState<Timeline[]>([]);
  const [currentSubStep, updateCurrentSubStep] = useState<number>(0);
  const [selectedOptions, updateSelectedOptions] = useState<
    Record<string, string>
  >({
    Producto: 'Ventana',
    Material: 'Aluminio',
    Apertura: 'Corredera',
    Movilidad: 'Fija',
    Color: 'Blanco',
    'Tipo de cristal': 'Simple',
  });
  // TODO: remove above hard coded values
  const [subStepsSnapshots, updateSubStepsSnapshots] = useState<
    Record<string, Timeline[]>
  >({});

  const resetTimelineState = () => {
    updateSubSteps([]);
    updateCurrentSubStep(0);
    updateSelectedOptions({});
    updateSubStepsSnapshots({});
  };

  return {
    subSteps,
    currentSubStep,
    selectedOptions,
    subStepsSnapshots,
    updateSubSteps,
    updateCurrentSubStep,
    updateSelectedOptions,
    updateSubStepsSnapshots,
    resetTimelineState,
  };
};

export default useTimelineState;

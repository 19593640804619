import { themes } from '@digital-retail/journey-ui-kit';
import { BUSINESS_UNITS } from '../utils/constants';
import * as falabellaThemes from './falabella';
import * as sodimacThemes from './sodimac';

const getThemeData = (theme: string) => {
  if (theme === BUSINESS_UNITS.SODIMAC) {
    return { ...themes[0], ...sodimacThemes };
  } else {
    return { ...themes[1], ...falabellaThemes };
  }
};

export default getThemeData;

export const FONT_SIZES = {
  EXTRA_LARGE: '20px',
  LARGE: '18px',
  REGULAR: '14px',
  SMALL: '12px',
  MOBILE_HEADING: '17px',
  DESKTOP_HEADING: '24px',
  STANDARD: '16px',
};

export const COLORS = {
  PRIMARY: '#0072ce',
  DARK_GRAY: '#333',
  MEDIUM_GRAY: '#bfbfbf',
  WHITE: '#ffffff',
  NEUTRAL_GRAY_LIGHT: '#f5f5f5',
  NEUTRAL_GRAY_100: '#20102b',
  NEUTRAL_GRAY_60: '#9b959f',
  DARK_GRAY_VARIANT_1: '#131313',
  GRAY_300: '#d1d1d1',
  NEUTRAL_GRAY_80: '#636363',
  ERROR: '#dd0021',
  NEUTRAL_GRAY_MEDIUM: '#8c8c8c',
  GRAY_DARK: '#ededed',
  NEUTRAL_GRAY_COLD: '#595959',
  BLACK: '#000000',
  CHARCOAL_GRAY: '#515151',
  SILVER_GRAY: '#e3e3e3',
  LIGHT_GREEN: '#d7f0d9',
  DARK_GREEN: '#185926',
  BLUE_GRAY: '#4c5866',
};

export const ZINDEX = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5,
  LEVEL_6: 6,
  LEVEL_7: 7,
  LEVEL_8: 8,
  LEVEL_9: 9,
  LEVEL_10: 10,
};

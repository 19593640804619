import { AppActionTypes, AppDataModel } from './app-data.model';
import { ACTIONS } from './constants';

export const reducerFn = (state: AppDataModel, action: AppActionTypes) => {
  if (action.type === ACTIONS.UPDATE_APP_DATA) {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === ACTIONS.UPDATE_STEPPER_INFO) {
    return {
      ...state,
      stepper: {
        ...state.stepper,
        ...action.payload,
      },
    };
  } else {
    return state;
  }
};

/* istanbul ignore file */
import { createContext } from 'react';
import useTimelineState from './useTimelineState';
import { timeLineInitialState } from './initial-state';
import { TimelineContext } from './timeline-state.model';

const TimeLineContext = createContext<TimelineContext>(timeLineInitialState);

const TimeLineProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useTimelineState();

  return (
    <TimeLineContext.Provider value={value}>
      {children}
    </TimeLineContext.Provider>
  );
};

export { TimeLineContext, TimeLineProvider };

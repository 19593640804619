import React from 'react';
import { CardContainer, CardImage, CardTitle, CardDescription } from './styles';
import { CardModel } from '../../pages/introduction/introduction.model';
import DOMPurify from 'dompurify';
import { updateImageUrlHost } from '../../utils';

const Card: React.FC<{ data: CardModel }> = ({ data }) => {
  const imageUrl = updateImageUrlHost(data?.imageDesktop?.url) || '';
  const title = data?.title || '';
  const sanitizedDescription = DOMPurify.sanitize(data.description || '');

  return (
    <CardContainer data-testid="card">
      <CardImage src={imageUrl} alt={title} />
      <CardTitle>{title}</CardTitle>
      <CardDescription
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
      ></CardDescription>
    </CardContainer>
  );
};

export default Card;

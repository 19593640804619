import { useContext, useEffect } from 'react';
import { AppContext } from '../providers';
import getThemeData from '../themes';
import { API_STATUS } from '../utils/constants';
import { fetchAppData } from '../services/app-data.service';
import { getCurrentBusinessUnit } from '../utils';

const useApp = () => {
  const { appActions } = useContext(AppContext);
  const currentTheme = getCurrentBusinessUnit();

  useEffect(() => {
    async function fetchData() {
      try {
        appActions.updateAppData({
          status: API_STATUS.LOADING,
        });
        const appData = await fetchAppData();
        appActions.updateAppData(appData);
      } catch (error) {
        appActions.updateAppData({
          status: API_STATUS.ERROR,
        });
      }
    }
    fetchData();
  }, []);

  return {
    themeData: getThemeData(currentTheme),
  };
};

export default useApp;

import { useContext } from 'react';
import useDevice from '../../hooks/useDevice';
import { AppContext } from '../../providers';
import { OnClickStepper } from '@digital-retail/journey-ui-kit';
import useResetState from '../../hooks/useResetState';

const useStepper = () => {
  const { isDesktop } = useDevice();
  const { appActions, appState } = useContext(AppContext);
  const { resetNextStepsToCurrentStep } = useResetState();

  const { stepper, pages } = appState;
  const { mobileTitles, desktopTitles, currentStep, standalonePagesCount } =
    stepper;
  let stepperTitles = mobileTitles;

  if (isDesktop) {
    stepperTitles = desktopTitles;
  }

  const onClick: OnClickStepper = (index: number) => {
    const adjustedCurrentStep = index + standalonePagesCount;
    resetNextStepsToCurrentStep(adjustedCurrentStep, pages);
    appActions.updateStepperInfo({ currentStep: adjustedCurrentStep });
  };
  const adjustedCurrentStep = currentStep - standalonePagesCount;

  return {
    stepperTitles,
    currentStep: adjustedCurrentStep,
    onClick,
  };
};

export default useStepper;

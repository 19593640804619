import usePageWrapper from '../../pages/page-wrapper/usePageWrapper';
import { noop } from '../../utils';
import { BUTTON_POSITIONS } from '../../utils/constants';
import Button from '../button';
import { ButtonsContainer } from './styles';

const NextAndBackButtons: React.FC<{
  nextButtonText?: string;
  backButtonText?: string;
  isDisabled?: boolean;
  dataTestId?: string;
  isIconVisible?: boolean;
  goToNextPageFn?: () => void;
  goToPreviousPageFn?: () => void;
  useCustomFunctions?: boolean;
  hideBackButton?: boolean;
  hideNextButton?: boolean;
  position?: 'left' | 'right';
}> = ({
  nextButtonText = 'Continuar',
  backButtonText = 'Volver',
  isDisabled,
  dataTestId,
  isIconVisible,
  goToNextPageFn = noop,
  goToPreviousPageFn = noop,
  useCustomFunctions = false,
  hideBackButton = false,
  hideNextButton = false,
  position = BUTTON_POSITIONS.RIGHT,
}) => {
  const { goToNextPage, goToPreviousPage } = usePageWrapper();

  return (
    <ButtonsContainer data-testid={dataTestId}>
      {!hideBackButton && (
        <Button
          buttonText={backButtonText}
          buttonType="ghost"
          onButtonClick={
            useCustomFunctions ? goToPreviousPageFn : goToPreviousPage
          }
          isIconVisible={isIconVisible}
          position={position}
        />
      )}
      {!hideNextButton && (
        <Button
          buttonText={nextButtonText}
          isDisabled={isDisabled}
          onButtonClick={useCustomFunctions ? goToNextPageFn : goToNextPage}
        />
      )}
    </ButtonsContainer>
  );
};

export default NextAndBackButtons;

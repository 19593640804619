import { Stepper } from '../../components';
import Loader from '../../components/loader';
import NextAndBackButtons from '../../components/next-and-back-buttons';
import useResizeObserver from '../../hooks/useResizeObserver';
import { API_STATUS } from '../../utils/constants';
import { PAGE_NAMES } from './constants';
import { Wrapper } from './styles';
import usePageWrapper from './usePageWrapper';

const PageWrapper: React.FC = () => {
  const { Page, pageData, hideStepper, status, currentStep } = usePageWrapper();
  const { appRef } = useResizeObserver(currentStep);

  if (status === API_STATUS.LOADING || status === API_STATUS.IDLE) {
    return <Loader />;
  }
  if (status === API_STATUS.ERROR) {
    return <div>Error...</div>;
  }
  if (!Page) {
    return <div>Page not found...</div>;
  }
  // TODO: Add Error and Page Not Found Components

  return (
    <Wrapper ref={appRef}>
      {hideStepper ? null : <Stepper />}
      <Page data={pageData} />
      {[
        PAGE_NAMES['JOURNEYS_PAGE_PRODUCT_DETAILS'],
        PAGE_NAMES['JOURNEYS_PAGE_TIMELINE'],
      ].includes(pageData.contentTypeUid) ? (
        // istanbul ignore next
        <div style={{ marginTop: '32px' }}>
          <NextAndBackButtons
            nextButtonText="Continuar"
            backButtonText="Volver"
          />
        </div>
      ) : null}
      {/* TODO: Remove the above Cta once all pages are developed */}
    </Wrapper>
  );
};

export default PageWrapper;

import styled from 'styled-components';
import { mediaQuery } from '../../../utils/media-query';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 19px 16px 19px;
  border: 1px solid ${COLORS.MEDIUM_GRAY};
  border-radius: 4px;
  align-items: center;
  width: 256px;
  text-align: center;
  color: ${COLORS.DARK_GRAY};
  gap: 2px;
  box-sizing: border-box;

  ${mediaQuery.desktopToUp(`
    padding: 0 19.5px 19.5px 19.5px;
    width: 213px;
  `)}
`;

export const CardImage = styled.img`
  width: 90px;
  height: 90px;
`;

export const CardTitle = styled.div`
  font-weight: 700;
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
`;

export const CardDescription = styled.div`
  font-weight: 400;
  font-size: ${FONT_SIZES.SMALL};
  line-height: 16px;
`;

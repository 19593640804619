import {
  AddToCartInfo,
  AddToCartRequestBody,
  AddToCartResponse,
  SessionInfoResponse,
} from '../models/addtocart.service.model';
import { getCurrentAppEnvironment, getCurrentTenant } from '../../utils';
import {
  ATG_CART_URL,
  ATG_SITE_IDS,
  FETCH_SESSION_INFO_URL,
  TENANTS,
} from '../../utils/constants/urls';

export const getFetchSessionInfoUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const url = `${baseUrl}/${FETCH_SESSION_INFO_URL}`;

  return url;
};

export const getAddToCartAtgUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const siteId = ATG_SITE_IDS[tenant];
  const url = `${baseUrl}/${ATG_CART_URL}?pushSite=${siteId}`;

  return url;
};

export const atgAddToCart = async (productsData: AddToCartInfo) => {
  try {
    const fetchSessionInfoUrl = getFetchSessionInfoUrl();
    const sessionData = await fetch(fetchSessionInfoUrl);
    const sessionDataJson = (await sessionData.json()) as SessionInfoResponse;
    const {
      state: { sessionConfirmationNumber = '' },
      success,
    } = sessionDataJson;

    if (success && sessionConfirmationNumber) {
      const addToCartAtgUrl = getAddToCartAtgUrl();
      const addToCartRequestBody: AddToCartRequestBody = {
        sessionConfirmationNumber,
        formSubmissionData: productsData,
      } as any;
      const addToCartAtgResponse = await fetch(addToCartAtgUrl, {
        method: 'POST',
        headers: {},
        body: JSON.stringify(addToCartRequestBody),
      });
      const addToCartAtgResponseJson =
        (await addToCartAtgResponse.json()) as AddToCartResponse;

      return addToCartAtgResponseJson;
    }

    return { success: false };
  } catch (error) {
    return { success: false };
  }
};
